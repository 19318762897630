// Customizations specific to Material.
@use '@angular/material' as mat;
@import 'palette';
@import './mixins';

@include mat.core();

$typography-config: mat.m2-define-typography-config(
  $font-family: '"Manrope", sans-serif',
  $body-1: mat.m2-define-typography-level(14px, 20px, 400),
);

$my-app-primary: mat.m2-define-palette($black-palette, 500);
$my-app-accent: mat.m2-define-palette($violet-palette);
$my-app-warn: mat.m2-define-palette($red-palette);

$my-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $my-app-primary,
      accent: $my-app-accent,
      warn: $my-app-warn,
    ),
    typography: $typography-config,
    density: 0,
  )
);

@include mat.all-component-themes($my-theme);

div.mat-expansion-panel-body {
  padding: 0;
}

.mdc-line-ripple {
  display: none !important;
}

.mdc-text-field:not(.mdc-text-field--disabled) {
  border: 1px solid var(--border-color2);
  border-radius: 0;
  background-color: white !important;
}

.mat-mdc-unelevated-button.mat-primary,
.mat-mdc-raised-button.mat-primary,
.mat-mdc-fab.mat-primary,
.mat-mdc-mini-fab.mat-primary {
  color: var(--main-color1) !important;
}

th.mat-mdc-header-cell {
  font-weight: bold;
  color: var(--main-color6);
  font-size: 0.875rem;
}

mat-spinner {
  position: fixed;
  width: 6%;
  top: 47%;
  height: 6%;
  left: 47%;
  transform: translate(-47%, -47%);
}

.mat-mdc-button.mat-mdc-button-base,
.mat-mdc-raised-button.mat-mdc-button-base,
.mat-mdc-unelevated-button.mat-mdc-button-base,
.mat-mdc-outlined-button.mat-mdc-button-base {
  // Not sure how to do this properly via the MaterialUI theme scaling function.
  height: 40px; // Note: Other fields are 48px high, but have 1px border all around. We add 2px to compensate
  font-weight: 600;
  box-shadow: none;
}

.mat-mdc-mini-fab,
.mat-mdc-mini-fab:hover {
  border-radius: 0 !important;
  box-shadow: unset !important;
}

button.mat-mdc-outlined-button {
  min-width: initial;
  position: relative;
  @include outline-base;
}

.mat-form-field.no-padding {
  .mat-form-field-wrapper {
    padding-bottom: 0;
  }
}

button.mat-mdc-button-base::after {
  content: '';
  position: absolute;
  background: transparent;
  top: 0;
  left: 0;
  z-index: 1;
  outline: 0 solid red;
  transition: outline-width 11s;
}

button.mat-mdc-outlined-button:not(.mat-button-disabled):hover {
  color: var(--main-color1);
  background-color: var(--main-color3);
}

button.mat-mdc-outlined-button {
  border-radius: 50px;
  white-space: nowrap;
}

.mat-mdc-raised-button,
.mat-mdc-unelevated-button {
  &.mat-primary {
    border-radius: 0;
    border: none;
    // Cutting the corner
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      border-top: 10px solid var(--main-color1);
      border-left: 10px solid var(--main-color3);
      width: 0;
    }

    &:hover {
      box-shadow: none;
    }
  }

  &.mat-warn {
    border-radius: 0;
    border: none;
    // Cutting the corner
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      border-top: 10px solid var(--main-color1);
      border-left: 10px solid var(--main-color5);
      width: 0;
    }

    &:hover {
      box-shadow: none;
    }

    &:not(:disabled) {
      color: white;
    }
  }

  &.mat-accent {
    border-radius: 0;
    border: none;
    // Cutting the corner
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      border-top: 10px solid var(--main-color1);
      border-left: 10px solid var(--main-color13);
      width: 0;
    }

    &:hover {
      box-shadow: none;
    }
  }

  &:disabled {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      border-top: 10px solid white;
      border-left: 10px solid var(--main-color2);
      width: 0;
    }
  }
}

.mat-mdc-outlined-button {
  &.mat-warn {
    border: 1px solid red;
  }
}

.cdk-overlay-pane {
  margin-top: 5px;
  max-height: 90%;
}

//Used for time-log-input highlighting of logged days
.logged-date {
  & .mat-focus-indicator {
    background: rgba($main-color3, 0.15);
  }
}

.holiday-date {
  & .mat-focus-indicator {
    background: rgba(silver, 0.15);
  }
}

.reimbursement-table-holder {
  .mat-form-field-flex {
    border: none;
  }
}

.mat-paginator-page-size {
  .mat-form-field-flex {
    border: none;
  }
}

.mat-autocomplete-panel {
  min-width: fit-content !important;
}

.calendar-directive-selected {
  & .mat-calendar-body-cell-content {
    background-color: var(--main-color3);
    color: rgba(0, 0, 0, 0.87);
  }
}

.general-search,
.holiday-details {
  & .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

mat-tab-header {
  & .mat-mdc-tab-labels {
    font-size: 16px;

    & .mat-tab-label-active {
      opacity: 1;
    }
  }

  .drop-calendar {
    width: 30rem;
  }
}

.mdc-tab {
  font-size: 16px !important;
  letter-spacing: 0 !important;
}

.drop-calendar .mat-mdc-menu-content {
  width: 280px !important;
}

.mdc-tooltip__surface {
  background-color: var(--main-color6) !important;
}

.multiline-tooltip {
  white-space: pre-line;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  background-color: var(--main-color1) !important;
  border: 1px solid var(--main-color6);
  border-radius: 0.5rem;
}

.mat-mdc-chip.small {
  @include mat.chips-overrides((
    container-height: 1.5rem
  ));
}

.mat-mdc-chip.secondary {
  border: 1px solid var(--main-color2);
  border-radius: 0.5rem;

  .mat-mdc-chip-action-label:not(:disabled) {
    color: var(--main-color2) !important;
  }
}

.mat-mdc-row:hover td,
.mat-mdc-row:hover mat-cell {
  background-color: var(--main-color9);
}

.mat-mdc-dialog-actions {
  padding: 20px !important;
}
